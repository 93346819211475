<template>
    <div class="container">
        <el-table :data="tableHostData" style="width: 100%" v-loading="loading" element-loading-text="加载中...">
            <el-table-column label="ID" prop="id" width="100" align="center">
            </el-table-column>
            <el-table-column label="主机名称" prop="name">
            </el-table-column>
            <el-table-column label="IP" prop="ip">
            </el-table-column>
            <el-table-column label="位置" prop="room">
            </el-table-column>
            <el-table-column label="管理者" prop="manager">
            </el-table-column>
            <el-table-column label="容灾信息">
                <template slot-scope="scope">
                    <p v-for="(item,index) in scope.row.ha" :key="index">
                        {{item}}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="设置容灾" width="100">
                <template slot-scope="scope">
                    <el-link type="primary" icon="el-icon-setting" @click="setHa(scope.row)">设置</el-link>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagenation">
            <el-pagination
                    @current-change="changeHostPage"
                    :current-page="host.page"
                    :page-size="host.limit"
                    layout="total, prev, pager, next"
                    :total="hostTotal">
            </el-pagination>
        </div>

        <el-dialog title="设置容灾信息" :visible.sync="haPop" :close-on-click-modal="false" @close="closePop" width="40%">
            <el-form :model="haForm" label-width="140px" @submit.prevent="submitHa" :close-on-click-modal="false">
                <el-form-item label="主机名称" prop="dev_id">
                    <p>{{haForm.devName}}({{haForm.devIp}})</p>
                </el-form-item>
                <el-form-item label="目标主机" prop="note">
                    <el-select v-model="haForm.target_dev_id" filterable>
                        <el-option
                                v-for="item in devOptionlist"
                                :key="item.id"
                                :label="item.name + '(' + item.ip + ')'"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="工作模式">
                    <el-radio-group v-model="haForm.workmode">
                        <el-radio label="镜像">镜像</el-radio>
                        <el-radio label="备份">备份</el-radio>
                        <el-radio label="其他">其他</el-radio>
                    </el-radio-group> &nbsp;
                    <el-input v-model="haForm.other" style="width: 100px" size="small" v-show="haForm.workmode=='其他'"></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="note">
                    <el-input v-model="haForm.note" type="textarea" :rows="3"></el-input>
                </el-form-item>
                <div style="margin-top:30px; text-align: center">
                    <el-button type="primary" native-type="submit" @click.prevent="submitHa">提交</el-button>
                    <el-button @click="closePop">关闭</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "GroupDevice",
        props: {
            groupId: {
                type: Number
            },
            keys: {
                type: String
            }
        },
        data() {
            return {
                tableHostData: [],
                host: {
                    keys: '',
                    page: 1,
                    limit: 10,
                },
                hostTotal: 0,
                loading: false,
                haPop: false,
                haForm: {
                    workmode: 1,
                    other: ''
                },
                devOptionlist: [],
            }
        },
        created() {
            this.getHostList();
        },
        methods: {
            getHostList () {
                //使用try catch 捕获异常
                try {
                    this.loading = true;
                    this.host.group_id = this.groupId;
                    this.host.keys = this.keys;
                    this.host.type = 1;
                    this.$get(this.$api.GetDeviceList, this.host).then(async (res) => {
                        this.loading = false;
                        let list = res.data.list;
                        for (let i = 0; i < list.length; i++) {
                            let data = await this.getHostHaInfo(list[i].id);
                            if (data.data.length > 0) {
                                list[i].ha = data.data;
                            } else {
                                list[i].ha = [];
                            }
                        }
                        this.tableHostData = res.data.list;
                        this.hostTotal = res.data.total_count;
                    });
                } catch (err) {
                    console.log(err)
                }

            },
            getHostHaInfo(id) {
                return this.$get(this.$api.GetHostHaInfo, {id: id});
            },
            changeHostPage (val) {
                this.host.page = val
                this.getHostList()
            },
            searchHost() {
                this.host.page = 1;
                this.tableHostData = [];
                this.hostTotal = 0;
                this.getHostList();
            },
            //设置容灾信息
            setHa(row) {
                this.haForm.devId = row.id;
                this.haForm = {
                    dev_id: row.id,
                    devName: row.name,
                    devIp: row.ip
                }
                this.haPop = true;
                //加载目标主机，目标主机必须是该集群下的主机
                this.getTargetHost();
            },
            submitHa() {
                if (this.haForm.target_dev_id == '') {
                    this.$message.warning('请选择目标主机');
                    return false;
                }
                if (this.haForm.target_dev_id === this.haForm.dev_id) {
                    this.$message.warning('请选择其他目标主机');
                    return false;
                }

                this.haForm.group_id = this.host.group_id;
                this.$post(this.$api.SetHa, this.haForm).then(() => {
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                    this.haPop = false;
                    this.activeName = 'ha';
                    this.getHaList();
                });
            },
            //加载目标主机，目标主机必须是该集群下的主机
            getTargetHost() {
                this.host.group_id = this.groupId;
                this.$get(this.$api.GetDeviceList, this.host).then((res) => {
                  this.devOptionlist = res.data.list;
                });
            },
            closePop() {
                this.haPop = false;
            },
        }
    }
</script>

<style scoped>
.pagenation{
    margin: 10px;
    text-align: center;
}
</style>
