<template>
    <div class="container">
        <el-table :data="tableHaData" style="width: 100%" v-loading="loading" element-loading-text="加载中...">
            <el-table-column label="ID" prop="id" width="100" align="center">
            </el-table-column>
            <el-table-column label="主机名称" prop="devname">
                <template slot-scope="scope">
                    {{scope.row.devname}}({{scope.row.devip}})
                </template>
            </el-table-column>
            <el-table-column label="目标设备" prop="target">
            </el-table-column>
            <el-table-column label="工作模式">
                <template slot-scope="scope">
                    {{scope.row.workmode}}
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <span class="row-opt" @click="deleteHa(scope.row.id)"><el-link type="primary" icon="el-icon-delete">删除</el-link></span>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagenation">
            <el-pagination
                    @current-change="changeHaPage"
                    :current-page="ha.page"
                    layout="total, prev, pager, next"
                    :total="haTotal">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: "GroupHa",
        props: {
            groupId: {
                type: Number
            },
            keys: {
                type: String
            }
        },
        data() {
            return {
                tableHaData: [],
                ha: {
                    keys: '',
                    page: 1,
                    limit: 10,
                },
                haTotal: 0,
            }
        },
        created() {
            this.getHaList();
        },
        methods: {
            getHaList() {
                this.loading = true;
                this.ha.group_id = this.groupId;
                //this.ha.keys = this.keys;
                this.$get(this.$api.GetHaList, this.ha).then((res) => {
                    this.loading = false;
                    if (res.code === 0) {
                        this.tableHaData = res.data.list;
                        this.haTotal = res.data.total_count;
                    } else {
                        this.$message.error(res.message)
                    }
                });
            },
            changeHaPage(val) {
                this.ha.page = val;
                this.getHaList();
            },
            searchHa() {
                this.ha.page = 1;
                this.tableHaData = [];
                this.haTotal = 0;
                this.getHaList();
            },
            deleteHa(id) {
                this.$confirm('此操作将删除所选项, 是否继续?', '提示', {
                    type: 'warning'
                }).then(() => {
                    this.$post(this.$api.DeleteHa, {id: id}).then((res) => {
                        if (res.code === 0) {
                            this.$message({
                                message: '操作成功！',
                                type: 'success'
                            });
                            this.getHaList();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            }
        }
    }
</script>

<style scoped>
    .pagenation{
        margin: 10px;
        text-align: center;
    }
</style>