<template>
    <div class="container">
        <div class="page-title">
            <h3>集群详情
                <el-divider direction="vertical"></el-divider>
                <span @click="$router.go(-1)">返回</span>
            </h3>
        </div>
        <el-card class="box-card" shadow="never">
            <el-row>
                <el-col :span="8">集群名称：{{group.name}}</el-col>
                <el-col :span="8">管理者：{{group.manager}}</el-col>
                <el-col :span="8">备注：{{group.note}}</el-col>
            </el-row>
        </el-card>
        <div class="tab-wrap">
            <el-form :inline="true" :model="searchForm" class="search-form" @submit.native.prevent v-if="activeName=='host'">
                <el-input placeholder="请输入主机名称或IP" size="small" v-model="searchForm.keys" style="width: 240px">
                    <el-button slot="append" icon="el-icon-search" native-type="submit" @click="search"></el-button>
                </el-input>
            </el-form>
        </div>

        <el-tabs v-model="activeName" type="card" @tab-click="switchTab">
            <el-tab-pane label="关系图谱" name="map">
                <div ref="graphChart" style="width:100%;height: 600px"></div>
            </el-tab-pane>
            <el-tab-pane label="集群主机" name="host">
                <hw-group-device :group-id="group.id" :keys="searchForm.keys" ref="refhost" v-if="activeName=='host'"></hw-group-device>
            </el-tab-pane>
            <el-tab-pane label="容灾信息" name="ha">
                <hw-group-ha :group-id="group.id" :keys="searchForm.keys" ref="refha" v-if="activeName=='ha'"></hw-group-ha>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import * as echarts from "echarts";
    import GroupDevice from "../components/pages/GroupDevice";
    import GroupHa from "../components/pages/GroupHa";
    const option = {
        legend: [{
            //selectedMode: 'single'
        }],
        // animationDuration: 1500,
        // animationEasingUpdate: 'quinticInOut',

        series: [
            {
                //name: '关系图',
                type: 'graph',
                //legendHoverLink: false, //启用图例hover联动高亮
                //edgeSymbol: ['none', 'arrow'],
                edgeSymbol: ['circle', 'arrow'],
                edgeSymbolSize: [4, 10],
                edgeLabel: {
                    fontSize: 12
                },
                layout: 'force', //采用力引导布局
                data: [],
                links: [],
                categories: [
                    {
                        "name": "主机"
                    },
                    {
                        "name": "集群"
                    }
                ],
                roam: true, //开启缩放和平移
                label: {
                    show: true,
                    position: 'right',
                    formatter: '{b}'
                },
                force: {
                    repulsion: 800,//相距距离
                    edgeLength: [150, 200],
                    layoutAnimation: true,
                    friction: 0.2, //这个参数能减缓节点的移动速度。取值范围 0 到 1。
                },
                lineStyle: {
                    opacity: 0.9,
                    width: 2,
                    type: 'solid', //线的类型 'solid'（实线）'dashed'（虚线）'dotted'（点线）
                    curveness: 0 //线条的曲线程度，从0到1
                },
            }
        ]
    };

    export default {
        name: "GroupDetail",
        components: {
            hwGroupDevice: GroupDevice,
            hwGroupHa: GroupHa
        },
        data() {
            return {
                id: parseInt(this.$route.query.id),
                group: {},
                activeName: 'map',
                searchForm: {
                    keys: ''
                },
            }
        },
        beforeRouteLeave (to, from , next) {
            this.clearChart();
            next();
        },
        created() {
            this.getGroupDetail();
        },
        methods: {
            createChart() {
                const myChart = echarts.init(this.$refs.graphChart);
                myChart.showLoading();
                //myChart.setOption(option, true);
                let app = [
                    {
                        id: "group_" + this.group.id,
                        name: this.group.name,
                        symbolSize: 50,
                        category: 1
                    }
                ];
                app[0].label = {
                    show: true,
                    fontWeight: 'bold',
                    fontSize: '18px'
                }

                this.$get(this.$api.GetGroupChart, {id: this.group.id}).then((res) => {
                    myChart.hideLoading();
                    let arr = res.nodes;
                    let nodes = [...app, ...arr];
                    option.series[0].data = nodes;
                    option.series[0].links = res.links;
                    myChart.setOption(option);
                });
            },
            clearChart() {
                const myChart = echarts.init(this.$refs.graphChart);
                //myChart.clear();
                option.series[0].data = [];
                myChart.setOption(option);
                myChart.dispose(); //销毁实例
            },
            getGroupDetail() {
                this.activeName = 'map';
                this.$get(this.$api.GetGroupInfo, {id: this.id}).then((res) => {
                    this.group = res.data;
                    this.createChart();
                });
            },
            switchTab(tab) {
                if (tab.name == 'map') {
                    this.$nextTick(()=>{
                        this.createChart();
                    });
                }
            },
            //搜索
            search() {
                if (this.activeName == 'map') {
                    this.activeName = 'host';
                }
                if (this.activeName == 'host') {
                    this.$refs.refhost.searchHost();
                } else if (this.activeName == 'ha') {
                    this.$refs.refha.searchHa();
                }
            },
        }
    }
</script>

<style scoped lang="less">
    .box-card{
        margin-right: 10px;
        margin-bottom: 10px;
        .search-form{
            float: right;
            width: 250px;
            margin-top: -5px;
        }
    }
    .tab-wrap{
        position: relative;
        .search-form{
            position: absolute;
            right: 0px;
            top: 0px;
            z-index: 999;
        }
    }
</style>
